* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  background-color: #f1f1f1;
  width: 100%;
  padding: 20px 10px;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.header-right {
  float: right;
}

.search-field {
  border: none;
  margin-bottom: 8px;
  padding: 6px;
  font-size: 17px;
}

@media screen and (width <= 500px) {
  .header-right {
    float: none;
  }
}

.active-breadcrumb {
  text-underline-offset: .35em;
  font-weight: bold;
  text-decoration: underline;
}

.modal-backdrop {
  display: none;
}

.modal {
  background: #00000080;
  overflow: auto !important;
}

#breadcrumbnavbar {
  margin-bottom: 0;
}
/*# sourceMappingURL=index.39c6099b.css.map */
